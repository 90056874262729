import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize.js";


const CategoryTabs = ({ selectedCategory, setSelectedCategory }) => {
  const {
    allStrapiCategory: { nodes: categoryList },
  } = useStaticQuery(graphql`
    query {
      allStrapiCategory (filter: {blog_articles: {elemMatch: {publishedAt: {ne: "null"}}}}) {
        nodes {
          name
          slug
          id
        }
      }
    }
  `);

  const { width: screenWidth } = useWindowSize();

  let defaultStyle =
    "p-4 cursor-pointer flex-grow text-center cursor-pointer border-y border-mutedGray";
  const selectedStyle = "text-whiteVariant bg-redVariant";
  const defaultBg = "bg-black bg-opacity-20";

  if (screenWidth < 1000) {
    defaultStyle =
      "p-4 cursor-pointer text-center cursor-pointer border border-mutedGray";
  } else {
    defaultStyle =
      "p-4 cursor-pointer flex-grow text-center cursor-pointer border-y border-mutedGray";
  }

  return (
    <div>
      <ul
        className={`flex text-lg text-grayVariant ${
          screenWidth < 1000 ? "gap-x-4 gap-y-4 flex-wrap" : ""
        }`}
      >
        <li
          className={`${defaultStyle}  ${
            selectedCategory === "All" ? selectedStyle : defaultBg
          }`}
          onClick={() => setSelectedCategory("All")}
        >
          All
        </li>

        {categoryList?.map((item) => {
          return (
            <li
              className={`${defaultStyle} ${
                selectedCategory === item?.name ? selectedStyle : defaultBg
              }`}
              key={item?.id}
              onClick={() => setSelectedCategory(item?.name)}
            >
              {item?.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryTabs;
