import React from "react";
import Logo from "../atoms/Logo";
import TwoColorHeader from "../atoms/TwoColorHeader";
import { Link } from "gatsby";

/*
<Link
  className="text-sm cursor-pointer"
  style={{ color: "white", textDecoration: "none" }}
  to={contactLinks.link}
>
*/
const StaticBanner = ({
  bannerData,
  index,
  useAssetUrl = true,
  useLogo = true,
  extraClasses = "",
  sizeClass = "",
  useLink = false,
  dropPage = false,
}) => {
  const baseUrl = useAssetUrl ? process.env.GATSBY_ASSET_URL : "";

  return (
    <div className={`${extraClasses} bg-bgPrimary`}>
      <div
        className={` pb-16 bg-cover bg-center ${
          index === 0 ? "pt-[122px]" : "pt-8"
        }`}
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${baseUrl}${bannerData.image.url})`,
        }}
      >
        <div className="flex flex-col justify-center items-center gap-y-4 bannerHeight">
          {useLogo && (
            <Logo src={`${baseUrl}${bannerData.logo.url}`} alt="Logo" />
          )}
          {!dropPage && (
            <>
              {useLink ? (
                <Link to={bannerData?.url}>
                  <TwoColorHeader
                    title={bannerData.title}
                    colorClassB="text-primary"
                    sizeClass={sizeClass}
                    className="font-bold text-5xl uppercase mb-2 drop-shadow-md"
                  />
                </Link>
              ) : (
                <TwoColorHeader
                  title={bannerData.title}
                  colorClassB="text-primary"
                  sizeClass={sizeClass}
                  className="font-bold text-5xl uppercase mb-2 drop-shadow-md"
                />
              )}
            </>
          )}{" "}
          {dropPage && (
            <TwoColorHeader
              title="Active | Drops"
              colorClassB="text-primary"
              sizeClass={sizeClass}
              className="font-bold text-5xl uppercase mb-2 drop-shadow-md"
            />
          )}
          <p className="uppercase drop-shadow-md ">{bannerData.subtext}</p>
        </div>
      </div>
    </div>
  );
};

export default StaticBanner;
