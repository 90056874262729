import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/Layout";
import StaticBanner from "../../components/molecules/StaticBanner";
import CategoryTabs from "../../components/molecules/CategoryTabs";
import TrendingArticles from "../../components/organisms/TrendingArticles";
import AppContext from "../../context/AppContext";
import Filter from "../../components/molecules/Filter";

import { Helmet } from "react-helmet";

const AllBLogs = ({ pageContext }) => {
  const pageName = "News Articles";
  const initialArticlesToShow = 8;
  const additionalArticlesToShow = 4;
  const sortOptions = ["Latest", "Oldest", "A-Z", "Z-A"];
  const {
    categoryFilter,
    updateCategoryFilter,
    updateSortedArticles,
    sortFilter,
    updateSortFilter,
  } = useContext(AppContext);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [finalArticleList, setFinalArticleList] = useState([]);
  const [error, setError] = useState(false);
  const [hideMoreBtn, setHideMoreBtn] = useState(false);
  const {
    strapiComponentVisualStaticBanner: bannerData,
    allStrapiBlogArticle: { nodes: allBlogArticles },
  } = useStaticQuery(graphql`
    query {
      strapiComponentVisualStaticBanner {
        id
        image {
          url
        }
        logo {
          url
        }
        subtext
        title
      }
      allStrapiBlogArticle(filter: { publishedAt: { ne: null } }) {
        nodes {
          id
          createdAt
          description
          featured_image {
            url
          }
          title
          slug
          article {
            data {
              article
            }
          }
          author {
            strapi_id
            username
            image {
              url
            }
          }
          blog_category {
            name
            slug
          }
        }
      }
    }
  `);

  useEffect(() => {
    const sortArticles = (list) => {
      let updatedList = [...list];
      switch (sortFilter) {
        case "Latest":
          updatedList = updatedList.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          break;
        case "Oldest":
          updatedList = updatedList.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
          break;
        case "A-Z":
          updatedList = updatedList.sort((a, b) => {
            let x = a.title.toLowerCase();
            let y = b.title.toLowerCase();
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
          });
          break;
        case "Z-A":
          updatedList = updatedList.sort((a, b) => {
            let x = a.title.toLowerCase();
            let y = b.title.toLowerCase();
            if (x < y) return 1;
            if (x > y) return -1;
            return 0;
          });
          break;
        default:
          break;
      }
      return updatedList;
    };

    if (categoryFilter) {
      let filteredArticleList = allBlogArticles;
      if (categoryFilter !== "All") {
        filteredArticleList = allBlogArticles.filter(
          (article) => article?.blog_category?.name === categoryFilter
        );
      }
      if (filteredArticleList.length) {
        filteredArticleList = sortArticles(filteredArticleList);
        setFilteredArticles(filteredArticleList);
        updateSortedArticles(filteredArticleList);
      } else {
        setError(true);
      }
    }
  }, [categoryFilter, sortFilter, allBlogArticles, updateSortedArticles]);

  useEffect(() => {
    if (filteredArticles?.length) {
      let articles = filteredArticles;
      if (filteredArticles.length > initialArticlesToShow) {
        articles = filteredArticles.slice(0, initialArticlesToShow);
      } else {
        setHideMoreBtn(true);
      }
      setFinalArticleList(articles);
    }
  }, [filteredArticles]);

  const handleLoadMoreArticles = () => {
    const nextCount = finalArticleList.length + additionalArticlesToShow;
    let articles = finalArticleList;
    if (filteredArticles.length > nextCount) {
      articles = filteredArticles.slice(0, nextCount);
    } else if ((filteredArticles.length = nextCount)) {
      articles = filteredArticles.slice(0, nextCount);
      setHideMoreBtn(true);
    } else {
      setHideMoreBtn(true);
    }
    setFinalArticleList(articles);
  };

  const handleCategoryChange = (category) => {
    if (error === true) setError(false);
    updateCategoryFilter(category);
  };

  const handleSortOptionChange = (option) => {
    updateSortFilter(option);
  };

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageName}`}</title>
      </Helmet>
      <StaticBanner bannerData={bannerData} index={0} />
      <div className="px-5 lg:px-20 py-6 flex flex-col gap-y-8 mb-24">
        <CategoryTabs
          selectedCategory={categoryFilter}
          setSelectedCategory={handleCategoryChange}
        />
        <div>
          <Filter
            selectedOption={sortFilter}
            options={sortOptions}
            onSelectOption={handleSortOptionChange}
          />
        </div>
        {error ? (
          "no articles found"
        ) : (
          <TrendingArticles
            showHeading={false}
            blog_articles={finalArticleList}
            loadMoreArticles={handleLoadMoreArticles}
            hideMoreBtn={hideMoreBtn}
          />
        )}
      </div>
    </Layout>
  );
};

export default AllBLogs;
