import React, { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const Filter = ({
  selectedOption = "",
  options = [],
  onSelectOption = () => null,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleOptionChange = (option) => {
    onSelectOption(option);
  };
  return (
    <div className="relative">
      {showDropdown && (
        <div
          onClick={setShowDropdown.bind(this, false)}
          className="fixed top-0 right-0 left-0 bottom-0"
          role="button"
          onKeyDown={setShowDropdown.bind(this, false)}
          tabIndex={0}
          aria-label="Close"
        ></div>
      )}
      <button
        onClick={toggleDropdown}
        className="px-4 py-3 border-2 border-mutedGray"
      >
        <FilterAltIcon />
      </button>
      <ul
        style={showDropdown ? { display: "block" } : { display: "none" }}
        className="hidden absolute top-[100%] min-w-[8rem] left-0 bg-mutedGray -mt-0.5 z-50"
      >
        {options.map((option) => {
          return (
            <li
              className={`${
                selectedOption === option
                  ? "text-whiteVariant bg-redVariant"
                  : ""
              }`}
              key={option}
            >
              <button
                className="px-6 py-3 w-full"
                onClick={handleOptionChange.bind(this, option)}
              >
                {option}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Filter;
